import { IDependant, IPatient, countries } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { currencies } from '@/components/Payment/currencies'
import { megalith_make_appointment, node_make_appointment, node_make_appointment_V2 } from '@/config/endpoints'
import { useMutation } from 'react-query'
import { ICopayPrimaryResponse } from '../Payments/useMegalithCopayPrimary'
import { isIomaClient, isOspreraClient, isPFA } from '@/utils/validations/ValidateCoverage'
import { IClientState } from '@/config/clients'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'
import { checkUtmParams } from '@/components/Utils/checkUtmParams'
import { isCountry } from '@/utils/countryUtils/isCountry'

/*
* payment_data: method: 'UmaCreditos', id: 'UmaCreditos' price: copayPrice.
* payment_data: method: 'Corporate',  id: '$CORPORATE_NAME', price: 0 (o un copago menor en algunos casos)
* payment_data: method: 'Subscription', id: '$SUSCRIPTION_ID', price: 0 
* payment_data: method: 'MercadoPago', id: '$MERCADOPAGO_ID': price: copayPrice
*/
interface IPaymentDataBackend {
	method: 'UmaCreditos'| 'Corporate' | 'Subscription' | 'MercadoPago'
	id: string,
	price: number
}


interface IMakeAppointment extends ICopayPrimaryResponse {
	/** Id que devuelve MP luego de pagar una consulta */
	mercadopagoId? : string
}


function useMakeAppointment(
	country: countries,
	uid: string,
	patient: IPatient | IDependant,
	symptoms : string,
	patientUid: string | undefined,
	client: IClientState,
	origin?: string | undefined,
) {
	const isVipPatient = isPFA(client) || isIomaClient(client) || isOspreraClient(client)
	const posthog = usePostHog()
	const migrationEnabled = useFeatureFlagEnabled('make-appointment-new')
	return useMutation(
		['UmaNodeServer-Query','useMakeAppointment', country],
		async (paymentData: IMakeAppointment) => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const path =
				process.env.NEXT_PUBLIC_COUNTRY === 'AR'
					? node_make_appointment
					: node_make_appointment_V2

			const headers = {
				Authorization: `Bearer ${token}`,
			}

			let paymentDataBackend: IPaymentDataBackend
			// TODO: Esto vuela cuando nos aseguremos de que la migración funciona bien
			if (paymentData.type === 'subscription'){
				paymentDataBackend = {
					method: 'Subscription',
					id: paymentData.approvalId??'suscription',
					price: 0,
				}
			}
			else if (paymentData.mercadopagoId){
				paymentDataBackend = {
					method: 'MercadoPago',
					id: paymentData.mercadopagoId ?? 'mercadopagoId not found',
					price: paymentData.copay,
				}
			}
			else if (paymentData.umaCredits && paymentData.umaCredits >= paymentData.copay){
				paymentDataBackend = {
					method: 'UmaCreditos',
					id: 'UmaCreditos',
					price: paymentData.copay
				}
			}
			else if (paymentData.copay === 0 ){
				paymentDataBackend = {
					method: 'Corporate',
					id: patient.corporate_norm || `UMA ${process.env.NEXT_PUBLIC_COUNTRY}`,
					price: paymentData.copay
				}
			}
			else {
				paymentDataBackend = {
					method: 'MercadoPago',
					id: paymentData.mercadopagoId ?? 'mercadopagoId not found',
					price: paymentData.copay,
				}
			}
			
			// TODO: Esto vuela cuando nos aseguremos de que la migración funciona bien
			const makeAppointmentRequestOld = {
				age: patient.dob,
				biomarker: [],
				category: 'GUARDIA_RANDOM',
				country: process.env.NEXT_PUBLIC_COUNTRY,
				cuit: '',
				destino_final: '',
				diagnostico: '',
				dt: '',
				dni: patient.dni,
				epicrisis: '',
				incidente_id: false,
				geo: { lat: null, lng: null },
				msg: 'make_appointment',
				motivo_de_consulta: symptoms,
				alertas: '',
				origin: origin??'',
				ruta: '',
				sex: patient.sex,
				specialty: 'online_clinica_medica',
				ws: patient.ws,
				uid: uid,
				dependantUid: patientUid !== uid ? patientUid : false,
				track_id: localStorage.getItem('trackId') || '',
				isdependant: patientUid !== uid,
				vip: isVipPatient,
				payment_data: {
					...paymentDataBackend,
					full_price: paymentDataBackend.price,
					currency: currencies[process.env.NEXT_PUBLIC_COUNTRY],
				},
			}

			const makeAppointmentRequest = {
				country: process.env.NEXT_PUBLIC_COUNTRY,
				dependantUid: patientUid !== uid ? patientUid : false,
				motivosDeConsulta: symptoms,
				paymentData,
			}
			if(migrationEnabled && isCountry('AR')){
				const response = await axios.post<{assignation_id: string}>(megalith_make_appointment, makeAppointmentRequest, {
					headers,
				})
				return response.data
			}else {
				const response = await axios.post<{assignation_id: string}>(path, makeAppointmentRequestOld, {
					headers,
				})
				return response.data
			}
			
			
		},
		{
			retry: 0,
			onSuccess: (response) => {
				checkUtmParams(posthog, uid, 'guardia', response.assignation_id)
			}
		}
	)
}

export default useMakeAppointment